const colors = {
  transparent: 'transparent', // 0
  black: '#111111', // 1
  white: '#ffffff', // 2
  headingColor: '#111',
  textColor: '#111', // 3
  labelColor: '#767676', // 4
  inactiveField: '#f2f2f2', // 5
  inactiveButton: '#b7dbdd', // 6
  inactiveIcon: '#EBEBEB', // 7
  primary: '#111', // 8
  primaryHover: '#3444f1', // 9
  secondary: '#ff5b60', // 10
  secondaryHover: '#FF282F', // 11
  yellow: '#fdb32a', // 12
  yellowHover: '#F29E02', // 13
  borderColor: '#1b1e25', //14
  primaryBoxShadow: '0px 8px 20px -6px rgba(235,77,75,0.6)',
  upworkButton: '#84E296', //15
  uselessButton: '#ffcf50', //16
  headingsColor: '#111',//17
};

export default colors;
