import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Container from 'common/components/UI/Container';
import Dogge from 'common/assets/image/portfolio/cooldoggo.png';
import Image from 'common/components/Image';
import GatsbyJS from 'common/assets/image/portfolio/gatsby_i.png';
import Link from 'gatsby-link';

import { FooterWrapper } from './footer.style';

const Footer = ({ row, copyrightStyle, noMargin }) => {
  const Data = useStaticQuery(graphql`
    query {
      portfolioJson {
        FOOTER_MENU {
          label
          path
        }
        SOCIAL_PROFILES {
          icon
          url
        }
      }
    }
  `);

  return (
    <FooterWrapper>
      <Container noGutter mobileGutter width="1200px">
        <Box {...row}>
          {/* <Box {...col}>
            <Heading
              as="h3"
              content="The footer"
              {...titleStyle}
            />
            <a href="#1">
              <Heading as="h3" content="Interesting aye?" {...linkStyle} />
            </a>
          </Box> */}
          <Link to="/"><Image src={Dogge} alt="Dogge Deal With It"/></Link>
        </Box>

        {/* <Box {...row}>
          <Box {...col}>
            <SocialProfile
              className="footer_social"
              items={Data.portfolioJson.SOCIAL_PROFILES}
              iconSize={40}
            />
            <Text
              as="span"
              content="© 2018 All rights reserved. "
              {...copyrightStyle}
            />
            <a href="#1">
              <Text as="span" content=" RedQ, Inc." {...copyrightStyle} />
            </a>
          </Box>
          <Box {...col} {...flexBox}>
            <Box {...contactItem}>
              <Text content="Need help?" {...contactTitle} />
              <Text content="redq.io" {...contactInfo} />
            </Box>
            <Box {...contactItem}>
              <Text content="Feel like talking" {...contactTitle} />
              <Text content="+12 34 56789" {...contactInfo} />
            </Box>
          </Box>
        </Box> */}

        <Box {...row} {...noMargin}>

            <Text
              as="span" className="frt_s"
              content="Built &amp; designed with the power of"
              {...copyrightStyle}
            />

          <Image
            src={GatsbyJS}
            alt="Gatsby Power"
            title="GatsbyJS"
            className="gatsby_img"
          />

        <Text
              as="span" className="frt_s"
              content="and a few 🍺"
              {...copyrightStyle}
            />

          {/* <Box {...col} {...flexBox}>
            <FooterNav>
              {Data.portfolioJson.FOOTER_MENU.map((item, index) => (
                <FooterNavItem key={`footer-nav-item-${index}`}>
                  <a href={item.path || '#1'}>{item.label}</a>
                </FooterNavItem>
              ))}
            </FooterNav>
          </Box> */}
        </Box>
      </Container>
    </FooterWrapper>
  );
};

Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  titleStyle: PropTypes.object,
  linkStyle: PropTypes.object,
  newsletterButton: PropTypes.object,
  copyrightStyle: PropTypes.object,
  contactItem: PropTypes.object,
  flexBox: PropTypes.object,
  contactTitle: PropTypes.object,
  contactInfo: PropTypes.object,
  noMargin: PropTypes.object,
};

Footer.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    justifyContent: 'center',
    ml: '-15px',
    mr: '-15px',
    mb: ['0', '20px', '20px', '20px', '20x'],
  },
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 3, 1 / 3],
    pl: '15px',
    pr: '15px',
    mb: ['40px', '0', '0', '0', '0', '0'],
  },
  titleStyle: {
    fontSize: ['16px', '18px'],
    fontWeight: '600',
    mb: ['20px', '25px'],
  },
  linkStyle: {
    fontSize: ['22px', '26px', '26px', '30px'],
    color: '#3444f1',
    mb: 0,
  },
  newsletterButton: {
    type: 'button',
    fontSize: '16px',
    pl: '20px',
    pr: '20px',
    colors: 'primary',
    minHeight: 'auto',
  },
  copyrightStyle: {
    fontSize: '14px',
    color: '#fff',
  },
  flexBox: {
    flexBox: true,
    justifyContent: 'center',
    // flexWrap: 'wrap'
  },
  contactItem: {
    // width: 1 / 2
  },
  contactTitle: {
    fontSize: ['15x', '15px', '16px', '16px', '16px'],
    mb: '10px',
  },
  contactInfo: {
    fontSize: ['16x', '16px', '18px', '18px', '20px'],
    fontWeight: '500',
    mb: 0,
  },
  noMargin: {
    mb: '0',
  },
};

export default Footer;
