import styled, { createGlobalStyle } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import Line from 'common/assets/image/portfolio/dotted-line.png';

export const GlobalStyle = createGlobalStyle`
  body{
    font-family: 'Chivo', sans-serif;
    line-height: 1.13;
    letter-spacing: .75px;
  }

  body:not(.wf-merriweather--loaded) {
    /* fallback font (Georgia) CSS config */
    /* line-height, letter spacing, font-size... */
   }
   
   body:not(.wf-merriweather--loaded) h1 {
    /* fallback font (Georgia) CSS config */ 
    /* line-height, letter spacing, font-size... */
   }
   
   .wf-merriweather--loaded {
    /* web font CSS config */ 
    /* line-height, letter spacing, font-size... */
   }
   
   .wf-merriweather--loaded h1 {
    /* web font CSS config */ 
    /* line-height, letter spacing, font-size... */
   }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Chivo', sans-serif;
    margin-top: 0;
  }

  div#mailgo .m-modal-content .m-by {
    display:none !important;
  }

  .m-modal .m-modal-content {
    border-radius: 0 !important;
  }

  section {
    position: relative;
  }

  .plm {
    @media only screen and (max-width: 480px) {
      padding: 0 30px;
    }
  }

  .mailGoWrp, .mailgo_a {
    @media only screen and (max-width: 480px) {
      width: 100%;
    }
  }

  .braniac_h,.braniac_d {
    @media only screen and (max-width: 480px) {
      text-align: left;
      line-height: 1.34;
    }
  }

  .ftl_t,.ftl_d {
    @media only screen and (max-width: 480px) {
      text-align: left !important;
      line-height: 1.34 !important;
    }
  }

  .drawer-content-wrapper{
    @media (max-width: 767px) {
      width: 300px!important;
    }
    .drawer-content {
      padding: 60px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media (max-width: 767px) {
        padding: 50px 40px 30px 40px;
      }
      .mobile_menu {
        margin-bottom: 40px;
        flex-grow: 1;
        @media (max-width: 767px) {
          margin-bottom: 30px;
        }
        li{
          margin-bottom: 35px;
          @media (max-width: 767px) {
            margin-bottom: 25px;
          }
          a{
            font-size: 20px;
            font-weight: 500;
            color: #343d48;
            position: relative;
            font-family: 'Chivo', sans-serif;
            transition: 0.15s ease-in-out;
            @media (max-width: 767px) {
              font-size: 18px;
            }
            &:hover {
              &:before {
                transform: scaleX(1);
                transform-origin: left center 0;
                transition: transform 0.35s cubic-bezier(0.43, 0.49, 0.51, 0.68);
              }
            }
            &:before{
              content: '';
              position: absolute;
              width: calc(100% - 8px);
              height: 11px;
              background: #c2c7fb;
              bottom: 2px;
              left: -4px;
              z-index: -1;
              transform: scaleX(0);
              transform-origin: right center 0;
              transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1) 0s;
            }
          }
          &.is-current {
            a {
              &:before {
                transform: scaleX(1);
                transform-origin: left center 0;
                transition: transform 0.35s cubic-bezier(0.43, 0.49, 0.51, 0.68);
              }
            }
          }
        }
      }
      .navbar_drawer_button button{
        width: 100%;
        font-family: 'Chivo', sans-serif;
      }
    }

    .reusecore-drawer__close{
      width: 34px;
      height: 34px;
      position: absolute;
      top: 20px;
      right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @media (max-width: 767px) {
        top: 15px;
        right: 15px;
      }
      &:before{
        content: '\f10b';
        font-family: Flaticon;
        font-size: 26px;
        color: #3444f1;
        transform: rotate(45deg);
        display: block; 
      }
    }
  }

`;

export const ContentWrapper = styled.div`
  overflow: hidden;

  .portfolio_navbar {
    z-index:1;
    margin-bottom: 50px;
  }
  // .sticky-nav-active {
  //   .portfolio_navbar {
  //     background: #fff;
  //     box-shadow: 0px 3px 8px 0px rgba(43, 83, 135, 0.08);
  //     padding: 15px 0;

  //     .main-logo {
  //       display: none !important;
  //     }
  //     .logo-alt {
  //       display: none;
  //     }
  //   }
  // }

  .portfolio_button {
    display: inline-flex;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    font-weight: 700;
    
    border-radius: 0;
    border: 3px solid ${themeGet('colors.borderColor', '#1b1e25')};
    background-color: #3e32d4;
    position: relative;
    min-height: 50px;
    text-transform: initial;
    transition: color .3s,background .3s,border-color .3s;
    overflow:hidden;
    font-size: 1.125rem;
    padding: .6875rem 1.25rem 1.125rem;
    min-height: 3.625rem;
    letter-spacing: 1.8px;
    line-height: .0769230769;
    color: #111;
    
    &:after {
      content: '';
      content: "";
      display: block;
      height: .4125rem;
      background: rgba(0,0,0,.15);
      position: absolute;
      left: 0;
      width: 100%;
      bottom: 0;
      right: 0;
      -webkit-transition: -webkit-transform .3s;
      transition: -webkit-transform .3s;
      transition: transform .3s;
      transition: transform .3s,-webkit-transform .3s;
    }

    &:hover 
    {
      &:after {
        transform:translateY(4px);
      }
    }
    
  }

  //SectionWrapperM

  .sectionWrapperM {
    background-color: #f9f9f9;
    padding-top: 80px;
    padding-bottom: 60px;
  }

  // TREAT BUTTON 

  .treat {
    --scale-x: 0;
    --scale-y: 0;
    pointer-events: none;
    display: block;
    position: absolute;
    top: 80%;
    left: calc(50% - .5rem);
    border-radius: 50%;
    width: 1em;
    height: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 5vmin;
    transform: 
      translate(
        calc( var(--x) * 1px ), 
        calc( var(--y) * 1px )
      ) 
      translate(-50%, -50%);
    pointer-events: none;
    animation: 
      treat-enter .1s ease-in backwards, 
      treat-exit 300ms linear 
      calc( (var(--lifetime, 3000) * 1ms) - 300ms) 
      forwards;
  
      @media only screen and (max-width: 624px) {
        top: 70%;
      }
  
    @keyframes treat-enter {
      from {
        opacity: 0;
      }
    }
    @keyframes treat-exit {
      to {
        opacity: 0;
      }
    }
    
    .inner {
      animation: inner-rotate .6s linear infinite;
      transform: rotate( 
        calc(-1turn * var(--direction) )
      ); 
      @keyframes inner-rotate {
        to { 
          transform: none;
        } 
      }
    }
  }

  // IB LOGO

.ib_log {
  display:block;
  font-weight:700;
  width: 50px;
  font-size:20px;
  cursor: pointer;
  color: #111;
}

  // PIXELATED
  // .portfolio_button {
  //   font-family: 'VT323', 'arial';
  //   background: none;
  //   border: none;
  //   outline: none;
  //   position: relative;
  //   min-height: 50px;
  //   text-transform: initial;
  //   font-size: 20px;
  //   box-shadow: 5px 0px #3e32d4, -5px 0px #3e32d4, 0px 5px #3e32d4, 0px -5px #3e32d4, 0px 15px #3129a2, -5px 10px #2A6CDA, 5px 10px #2A6CDA;
  //   background: #3e32d4;
  //   transition: box-shadow 0.1s, background 0.1s, padding 0.1s;
    
  //   &:active {
  //     box-shadow: 5px 5px #3e32d4, -5px 5px #3e32d4, 0px 10px #3e32d4, 0px 0px #3e32d4, 0px 15px #3129a2, -5px 10px #3e32d4, 5px 10px #3e32d4;
  //     background: #3e32d4;
  //     padding-top: 15px;
  //     padding-bottom: 5px;
  //     transition: box-shadow 0.1s, background 0.1s, padding 0.1s;
  //   }
    
  //   &:hover {
  //    opacity:0.8;
  //   }
  // }


  .active {
    &:before {
      content: '👑';
      display:block;
      position: absolute;
      width: calc(10% - 8px);
      height: auto;
      left:-24px;
      background: none;
      bottom: 6px;
      z-index: 1;
    }
  }

  .portfolio_navbar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: 0.35s ease-in-out;
    padding: 50px 0 30px 0;
    @media (max-width: 990px) {
      padding: 30px 0;
    }
    .logo-alt {
      display: none;
    }
    .main_menu {
      margin-right: 40px;
      li {
        display: inline-block;
        padding-left: 13px;
        padding-right: 20px;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
        &.is-current {
          a {
            color: #111;
            &:after {
              transform: scaleX(1);
              transform-origin: left center 0;
              transition: transform 0.35s cubic-bezier(0.43, 0.49, 0.51, 0.68);
            }
          }
        }
        a {
          padding: 5px;
          font-size: 16px;
          font-weight: 400;
          color: #111;
          position: relative;
          font-family: 'Chivo', sans-serif;
          line-height: 1.13;
          letter-spacing: .96px;
          transition: 0.15s ease-in-out;
          &:hover {
            &:after {
              transform: scaleX(1);
              transform-origin: left center 0;
              // transition: transform 0.35s cubic-bezier(0.43, 0.49, 0.51, 0.68);
            }
          }
          &:after {
            content: '👑';
            position: absolute;
            width: calc(10% - 8px);
            height: auto;
            background: none;
            bottom: 6px;
            left:-24px;
            z-index: -1;
            transform: scaleX(0);
            transform-origin: right center 0;
            // transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1) 0s;
          }
        }
      }
      @media (max-width: 990px) {
        
      }
    }
    .navbar_button {
      button {
        font-family: 'Chivo', sans-serif;
        line-height: 1.13;
        letter-spacing: .96px;
        font-weight: 700;
        background: none;
        color: ${themeGet('colors.headingsColor', '#0f2137')};
      }
      @media (max-width: 990px) {
       
      }
    }
    .reusecore-drawer__handler {
      display:none;
      @media (min-width: 991px) {
        display: none !important;
      }
      .hamburgMenu__bar {
        display:none;
        > span {
        }
      }
    }
  }
 

  // .sticky-nav-active {
  //   .portfolio_navbar {
  //     .main_menu {
  //       li {
  //         a {
  //           color: #111;
  //           &:after {
  //             background: #c2c7fb;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  .process_item_col {
    &:nth-child(2),
    &:nth-child(3) {
      .process_item {
        &:before {
          content: '';
          background-image: url(${Line});
          width: 165px;
          height: 35px;
          display: block;
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          left: -165px;
          top: 20px;
          @media (max-width: 990px) {
            width: 100px;
            left: -80px;
          }
          @media (max-width: 767px) {
            display: none;
          }
        }
      }
    }
    &:nth-child(3) {
      .process_item {
        &:before {
          transform: rotate(180deg);
        }
      }
    }
  }
`;

export const PrevButton = styled.div`
  position: relative;
  padding: 18px 10px;
  cursor: pointer;
  &:hover {
    span {
      background: #3444f1;
      @media (min-width: 991px) {
        width: 100px;
      }
    }
  }
  span {
    width: 18px;
    height: 2px;
    background: #d1d3de;
    display: block;
    position: relative;
    transition: 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    &:before,
    &:after {
      content: '';
      display: block;
      height: 2px;
      border-radius: 2px;
      background: inherit;
      position: absolute;
    }
    &:before {
      transform: rotate(-45deg);
      top: -4px;
      left: 0;
      width: 10px;
    }
    &:after {
      transform: rotate(45deg);
      width: 8px;
      bottom: -6px;
      left: 1px;
    }
  }
`;

export const NextButton = styled.div`
  position: relative;
  padding: 18px 10px;
  cursor: pointer;
  &:hover {
    span {
      background: #3444f1;
      @media (min-width: 991px) {
        width: 100px;
      }
    }
  }
  span {
    width: 18px;
    height: 2px;
    background: #d1d3de;
    display: block;
    position: relative;
    transition: 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    &:before,
    &:after {
      content: '';
      display: block;
      height: 2px;
      border-radius: 2px;
      background: inherit;
      position: absolute;
    }
    &:before {
      transform: rotate(45deg);
      top: -4px;
      right: 0;
      width: 10px;
    }
    &:after {
      transform: rotate(-45deg);
      width: 8px;
      bottom: -6px;
      right: 1px;
    }
  }
`;

export const ButtonWrapper = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  margin-top:10px;
`;

export const ButtonWrapperMail = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  margin-top:10px;

  @media (min-width: 990px) {
    margin-top:0px;
  }


  .mail_button {
    display: inline-flex;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    letter-spacing:0.2rem;
    font-weight:700;
    
    border-radius: 0;
    border: 3px solid ${themeGet('colors.borderColor', '#1b1e25')};
    background-color: #fff;
    position: relative;
    min-height: 50px;
    text-transform: initial;
    transition: color .3s,background .3s,border-color .3s;
    overflow:hidden;
    font-size: 1.125rem;
    padding: .6875rem 1.25rem 1.125rem;
    min-height: 3.625rem;
    letter-spacing: 1.8px;
    line-height: .0769230769;
    color: #111;
    
    &:after {
      content: '';
      display: block;
      height: .4125rem;
      background: rgba(0,0,0,.15);
      position: absolute;
      left: 0;
      width: 100%;
      bottom: 0;
      right: 0;
      -webkit-transition: -webkit-transform .3s;
      transition: -webkit-transform .3s;
      transition: transform .3s;
      transition: transform .3s,-webkit-transform .3s;
    }

    &:hover 
    {
      &:after {
        transform:translateY(4px);
      }
    }

    @media only screen and (max-width: 480px) {
      width: 100% !important;
    }
   
  }
`;